import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ChatPDF from './pages/ChatPDF';
import RagChatbot from "./pages/RagChatbot";
import FaceRec from "./pages/FaceRec";
import Login from './components/Login';
import Signup from './components/Signup';
import { AuthProvider } from "./context/AuthContext";

function App() {
    return (

        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/chatpdf" element={<ChatPDF />} />
                    <Route path="/chatbot" element={<RagChatbot />} />
                    <Route path="/facerec" element={<FaceRec />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />

                </Routes>
                <Footer />
            </Router>
        </AuthProvider>
    );
}

export default App;