import React, { useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";
import "../assets/css/style.min.css";
import "../assets/css/icons.min.css";
import { API_URL } from "../configs";

const FaceRecognition = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);
  const [showUpload, setShowUpload] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const startCamera = () => {
    setShowUpload(false);
    setCameraActive(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((error) => {
        console.error("Error accessing webcam:", error);
        alert(t("Could not access the camera. Please check your permissions."));
      });
  };

  const stopCamera = () => {
    setCameraActive(false);
    setShowUpload(true);
    const stream = videoRef.current.srcObject;
    const tracks = stream?.getTracks();
    tracks?.forEach((track) => track.stop());
    videoRef.current.srcObject = null;
  };

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const file = new File([blob], "capture.jpg", { type: "image/jpeg" });
      setSelectedImage(file);
      setAnalysisResult(null); // Reset previous result
      stopCamera(); // Stop the camera after capturing
    });
  };

  const handleAnalyzeImage = async () => {
    if (!selectedImage) {
      alert(t("Please upload or capture an image first!"));
      return;
    }

    setProcessing(true);
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/analyze-image/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAnalysisResult(response.data);
    } catch (error) {
      alert(t("Failed to analyze image. Please try again."));
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="bg-default-900">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <section style={{ marginTop: "100px" }} className="py-20">
        <div className="container text-center">
          <h1 className="text-4xl font-medium text-white mb-6">
            {t("Face Recognition and Analysis")}
          </h1>

          <p className="text-lg text-default-300 mb-10">
            {t("Upload an image or capture one from your camera to analyze it.")}
          </p>

          {/* Camera and Image Upload Section */}
          <div className="max-w-xl mx-auto bg-default-950/40 p-8 rounded-lg backdrop-blur-3xl mb-8">
          {showUpload && (<>
            <label className="text-lg text-white mb-2 block">
              {t("Upload an Image")}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setSelectedImage(e.target.files[0])}
              className="block w-full p-3 rounded-lg bg-default-700 text-white border border-default-400 mb-4"
            /></>)}

            {!cameraActive && (
              <button
                style={{marginRight: "10px"}}
                className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300 mb-4"
                onClick={startCamera}
              >
                {t("Capture from Camera")}
              </button>
            )}

            {cameraActive && (
              <div>
                <video ref={videoRef} autoPlay playsInline className="rounded-lg w-full mb-4" />
                <button
                  className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300 mb-4"
                  onClick={captureImage}
                >
                  {t("Capture Image")}
                </button>
                <button
                  className="bg-red-600 text-white py-2 px-6 rounded-full hover:bg-red-700 transition-all duration-300"
                  onClick={stopCamera}
                >
                  {t("Close Camera")}
                </button>
              </div>
            )}

            <button
              className="bg-primary text-white py-2 px-6 rounded-full hover:bg-primary-hover transition-all duration-300"
              onClick={handleAnalyzeImage}
              disabled={processing}
            >
              {processing ? t("Processing...") : t("Analyze Image")}
            </button>
          </div>

          {/* Results Section */}
          <div className="flex gap-8 justify-center items-start">
            {/* Selected Image Preview */}
            {selectedImage && (
              <div className="bg-default-950/40 p-4 rounded-lg backdrop-blur-3xl">
                <h2 className="text-lg text-white mb-2">
                  {t("Selected Image Preview")}
                </h2>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  className="w-48 h-auto mx-auto rounded-lg"
                />
              </div>
            )}

            {/* Analysis Result */}
            {analysisResult && (
              <div className="bg-default-950/40 p-4 rounded-lg backdrop-blur-3xl">
                <h2 className="text-lg text-white mb-4">
                  {t("Analysis Result")}
                </h2>
                <div className="text-left text-white">
                  <p>
                    <strong>{t("Dominant Emotion")}: </strong>
                    {analysisResult[0]?.dominant_emotion}
                  </p>
                  <p>
                    <strong>{t("Age")}: </strong>
                    {analysisResult[0]?.age}
                  </p>
                  <p>
                    <strong>{t("Dominant Gender")}: </strong>
                    {analysisResult[0]?.dominant_gender}
                  </p>
                  <p>
                    <strong>{t("Dominant Race")}: </strong>
                    {analysisResult[0]?.dominant_race}
                  </p>
                  <p>
                    <strong>{t("Face Confidence")}: </strong>
                    {analysisResult[0]?.face_confidence}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Hidden Canvas for Image Capture */}
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>
      </section>
    </div>
  );
};

export default FaceRecognition;
